

/* Phones, Stacked */
@media only screen and (min-width: 320px) {
  .outerContainer {
    background-image: url('../../../img/Compound/Compound-Overview/Compound-Overview-1a-600-330.jpg');
  }
  
  .innerContainer {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .contentContainer {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1em;
    border-radius: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .contentContainer .mapContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .locationDescContainer {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1em;
  }
}

/* Tablets in Portrait */
@media only screen and (min-width: 768px) {
  .outerContainer {
    background-image: url('../../../img/Compound/Compound-Overview/Compound-Overview-1a-1200-660.jpg');
  }

  .contentContainer {
    flex-direction: row;
    justify-content: stretch;
  }

  .contentContainer .mapContainer {
    flex-grow: 1;
  }

  .locationDescContainer {
    flex-grow: 1;
  }
}




.locationDescContainer .header {
  text-align: center;
  font-weight: bolder;
  font-size: 1.5em;
}

.locationDescContainer p.address {
  margin-left: 2em;
}