/* Phones, Burger Menu */
@media only screen and (min-width: 320px) {
  .outerContainer {
    background-image: url('../../../img/Compound/C-Building-Lane/C-Building-Lane-1-1200-655.jpg');
    justify-content: flex-start;
  }

  .innerContainer {
    background-color: rgba(174, 0, 0, 0.6);
    color: white;
    border-right: 2px solid black;
  }

  .messageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
  }

  .featuresHeader {
    font-size: 2em;
  }

  .featuresHeader {
    width: 100%;
    text-align: center;
    padding-bottom: 1em;
  }

  .reasonsContainer {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  .itemSymbol {
    text-align: right;
    padding-right: 1em;
    padding-top: 2em;
  }

  .itemInfo {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
  }

  .itemDesc span {
    display: block;
  }

  .itemDesc a {
    color: white;
    text-decoration: none;
    font-weight: bolder;
  }

  span.itemHeader {
    font-size: 1em;
    font-weight: bolder;
  }
}

@media only screen and (min-width: 1200px) {
  .outerContainer {
    background-image: url('../../../img/Compound/C-Building-Lane/C-Building-Lane-1-1800-982.jpg');
  }
}