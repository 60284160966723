@media only screen and (min-width: 320px) {
  .panelContainer {
    width: 100%;
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top: 1em solid black;
    display: flex;
  }

  .featuredContentContainer {
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 20px 10px rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Tablets in Portrait */
@media only screen and (min-width: 768px) {
  .panelContainer {
    background-attachment: fixed;
  }
  
  .featuredContentContainer {
    width: 40%;
  }
}