/* Phones, Stack verticallt */
@media only screen and (min-width: 320px) {
  .outerContainer {
    background-image: url('../../../img/Compound/View-Down-Lane/View-Down-Lane-1a-1200-553.jpg');
  }

  .innerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1em;
  }

  .contentContainer {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 1em;
    width: 100%;
    min-height: 10em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .contentContainer header {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
  }
}

@media (min-width: 1200px) {
  .outerContainer {
    background-image: url('../../../img/Compound/View-Down-Lane/View-Down-Lane-1a-2200-1013.jpg');
  }
}


