/* Phones, Stack verticallt */
@media only screen and (min-width: 320px) {
  .outerContainer {
    background-image: url('../../../img/Compound/Buildings-Front-to-Back/Buildings-Front-to-Back-1a-1200-439.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
  }

  .headerContainer {
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
  }

  .imgContainer, .descContainer {
    margin-top: 1em;
  }

  .contentContainer .imgContainer {
    width: 35%;
    float: left;
    margin-right: 2em;
    margin-bottom: 2em;
  }

  .imgContainer img {
    max-width: 100%; 
    height: auto;
    border: 1px solid black;
    box-shadow: 5px 5px 5px #000;
  }

  .redirectContainer {
    grid-column-end: span 2;
    text-align: center;
    border-top: 1px solid black;
    padding-top: 1em;
  }

  .important {
    text-align: center;
    font-weight: bold;
  }
}

/* Tablets in Portrait */
@media only screen and (min-width: 768px) {
  .outerContainer {
    background-attachment: fixed;
  }
}

@media only screen and (min-width: 1200px) {
  .outerContainer {
    background-image: url('../../../img/Compound/Buildings-Front-to-Back/Buildings-Front-to-Back-1a-2200-804.jpg');
  }

  .redirectContainer {
    border-top: none;
  }
}
