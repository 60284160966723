html {
  font-size: 14px;
}

button {
  padding: 0.5em 1em 0.5em 1em;
  background-color: rgb(174, 0, 0);
  color: white;
  font-weight: bold;
  border: 1px solid black;
  border-radius: 5px; 
}

input {
  height: 2.5em;
  border-radius: 5px;
}

a, a:visited {
  color: rgb(174, 0, 0);
  font-weight: bold;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul, ol {
  display: inline-block;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  overflow: auto;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
