
@media only screen and (min-width: 320px) {
  .contentContainer {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

@media only screen and (min-width: 768px) {
  .contentContainer {
    padding: 1em 10% 1em 10%;
  }
}

@media only screen and (min-width: 992px) {
  .contentContainer {
    padding: 1em 15% 1em 15%;
  }
}
