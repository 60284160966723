
  .outerContainer {
    align-items: initial;
    justify-content: center;
    gap: 1em;
    background-image: url('../../../img/Compound/Compound-Overview/Compound-Overview-1a-1200-660.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
 
  .pageHeaderContainer {
    width: 100%;
    background-color: rgba(255, 255, 255, 1.0);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    grid-column-start: span 2;
  }

  .pageHeaderContainer .header {
    text-align: center;
    font-size: 2em;
    margin-bottom: 1em;
  }

  .pricesOuterContainer {
    display: contents;
  }

  .sizeDetailsContainer {
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 1em;
  }

  .sizeDetailsContainer .sizeImagesContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .priceAndDescContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .priceAndDescContainer > .dotLeader {
    display: none;
  }

  .sizeHeader {
    border-bottom: 1px solid black;
    font-size: 1.5em;
    font-weight: bolder;
    text-align: center;
  }

/* Tablets in Portrait */
@media only screen and (min-width: 768px) {
  .outerContainer {
    background-attachment: fixed;
  }

  .pageHeader .desc {
    font-size: 1.5em;
  }

  .sizesContainer {
    display: flex;
  }

  .pricesOuterContainer {
    flex-grow: 1;
    max-width: 70%;
    font-size: 1em;
    display: flex;
    flex-direction: column;
  }

  .pricesOuterContainer > .priceAndDescContainer {
    flex-grow: 0;
    margin-top: 0.5em;
    display: flex;
    flex-wrap: wrap;
  }

  .pricesOuterContainer > .spacer {
    flex-grow: 1;
  }

  .pricesOuterContainer > .unitUsesContainer {
    font-size: 0.8em;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 2em;
  }

  .priceAndDescContainer {
    display: unset;
    flex-direction: unset;
    align-items: unset;
  }
  
  .priceAndDescContainer:nth-child(2) {
    margin-top: unset;
  }

  .priceAndDescContainer > .descContainer,
  .priceAndDescContainer > .priceContainer {
    flex-grow: 0;
  }

  .priceAndDescContainer > .dotLeader {
    flex-grow: 1;
    display: unset;
    border-bottom: 1px dotted black;
    padding-left: 2em;
    padding-right: 2em;
    margin-left: 0.25em;
    margin-right: 0.25em;
    margin-bottom: 0.15em;
  }

  .sizeDetailsContainer {
    flex-direction: row;
    row-gap: unset;
  }

  .sizeDetailsContainer .sizeImagesContainer {
    width: 40%;
  }

  .sizeHeader {
    border-bottom: unset;
  }
}

/* Mobile / Phone (Order and content rearranging) */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .pricesOuterContainer {
    display: contents;
  }

  .sizeHeader {
    order: 1;
  }

  .sizeDetailsContainer .sizeImagesContainer {
    order: 2;
  }

  .priceAndDescContainer {
    order: 3;
  }
  
  .pricesOuterContainer > .unitUsesContainer {
    order: 4;
  }
}
