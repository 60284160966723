@media only screen and (min-width: 320px) {
  .outerContainer {
    background-image: url('../../../img/Compound/Three-Doors/One-Door-Mobile-400-640.jpg');
    border-top: 2px solid black;
    justify-content: flex-end;
  }
  
  .innerContainer {
    background-color: rgba(255, 255, 255, 0.7);
    border-left: 2px solid black;
  }

  .messageContainer {
    font-size: 1.5em;
    display: flex;
    height: 50%;
    flex-direction: column;
    justify-content: space-around;
  }
}

/* Tablets in Landscape */
@media only screen and (min-width: 768px) {
  .outerContainer {
    background-image: url('../../../img/Compound/Three-Doors/Three-Doors-1200-900.jpg');
  }

  .messageContainer {
    font-size: 2em;
  }
}

/* Tablets in Landscape */
@media only screen and (min-width: 1200px) {
  .outerContainer {
    background-image: url('../../../img/Compound/Three-Doors/Three-Doors-1800-1350.jpg');
  }

  .messageContainer {
    font-size: 3em;
  }
}

.lineContainer {
  display: flex;
  align-items: center;
}

.lineContainer > div:nth-child(n + 1) {
  margin-left: 0.75em;;
}