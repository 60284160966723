@media only screen and (min-width: 320px) {
  .outerContainer {
    background-image: url('../../../img/Compound/Three-Doors/Three-Doors-1200-900.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1em;
  }

  .header {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
  }
}

/* Tablets in Portrait */
@media only screen and (min-width: 768px) {
  .outerContainer {
    background-attachment: fixed;
  }
}