@media only screen and (min-width: 320px) {
  .outerContainer {
    background-image: url('../../../img/Compound/C-Building-Lane/C-Building-Lane-1-1800-982.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1em;
  }

  .questionAndAnswerContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .questionContainer {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    font-weight: bold;
  }

  .answerContainer {
    margin-top: 1em;
  }
}

/* Tablets in Portrait */
@media only screen and (min-width: 768px) {
  .outerContainer {
    background-attachment: fixed;
  }
}