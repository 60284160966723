/*
// Small devices (landscape phones, 576px and up)
@media (min-width: 320px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { ... }
*/

/* Phones, Burger Menu */
@media only screen and (min-width: 320px) {
  .navBarContainer {
    width: 100%;
    background-color: rgb(174, 0, 0);
    font-size: 1.8em;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 36000;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .navBarContainer .nonNavContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .navBarContainer .nonNavContainer .logoContainer {
    width: 7.5em;
    height: 3em;
    background-color: rgb(174, 0, 0);
    background-image: url('../../../../img/SMS-Logo-White-Outline-500-237.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0.25em 0.5em 0.25em 0.5em;
    flex-grow: 0;
  }

  .navBarContainer .nonNavContainer .logoContainer:hover {
    cursor: pointer;
  }

  .navBarContainer .nonNavContainer .spacer {
    flex-grow: 0;
  }

  .navBarContainer .navItemsContainer {
    display: none;
    transition: 0.5s;
  }

  .navBarContainer.open .navItemsContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: 0.5s;
  }

  .navBarContainer .burgerContainer {
    padding: 0.5em;
    border: 1px solid white;
    border-radius: 0.25em;
    margin-right: 0.25em;
  }

  .navBarContainer.open .burgerContainer svg {
    transform: rotate(90deg);
    transition: 0.5s;
  }
}

/* Tablets in Portrait */
@media only screen and (min-width: 768px) {
  .navBarContainer {
    font-size: 0.8em;
    opacity: 1.0; /* debug */
    height: 2.5em;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .navBarContainer .nonNavContainer {
    flex-grow: 1;
  }

  .navBarContainer .nonNavContainer .logoContainer {
    width: 4.5em;
    height: 2em;
  }

  .navBarContainer .burgerContainer {
    display: none;
  }

  .navBarContainer .navItemsContainer {
    display: flex;
  }
}

@media only screen and (min-width: 992px) {
  .navBarContainer {
    font-size: 1em;
  }

}

@media only screen and (min-width: 1200px) {
  .navBarContainer {
    font-size: 1.1em;
    height: 5em;
    align-items: flex-end;
    transition: 0.4s;
  }

  .navBarContainer.scrolled {
    height: 2.5em !important;
    transition: 0.4s;
  }

  .navBarContainer .nonNavContainer .logoContainer {
    width: 11em;
    height: 4.5em;
    margin: 0.25em 1em 0.25em 1em;
    transition: 0.4s;
  }

  .navBarContainer.scrolled .nonNavContainer .logoContainer {
    width: 4.5em !important;
    height: 2em !important;
    transition: 0.4s;
  }

  .callContainer {
    margin-left: 1em;
  }
}
