/* Phones, Burger Menu */
@media only screen and (min-width: 320px) {
  .outerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5.5em;
  }
}

/* Tablets in Portrait */
@media only screen and (min-width: 768px) {
  .outerContainer {
    margin-top: 2.0em;
  }
}

@media only screen and (min-width: 1200px) {
  .outerContainer {
    margin-top: 5.5em;
    transition: 1s;
  }

  .outerContainer.scrolled {
    margin-top: 3.5em;
    transition: 1s;
  }
}

.outerContainer > * {
  flex-grow: 0;
}

.contentContainer {
  flex-grow: 1;
}