/* Phones */
@media (min-width: 320px) {
  .outerContainer {
    background-image: url('../../../img/Compound/Ten-Foot-Door-Section/Ten-Foot-Door-Section-1200-900.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1em;
  }
}

/* Tablets in Portrait */
@media only screen and (min-width: 768px) {
  .outerContainer {
    background-attachment: fixed;
  }
}