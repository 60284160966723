/* Phones */
@media (min-width: 320px) {
  .headerAndDirectionsContainer {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2em;
  }

  .headerAndDirectionsContainer .headerContainer {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
  }

  .formContainer {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.5em;
  }

  .formContainer .labelContainer {
    font-weight: bold;
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .formContainer .inputContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .formContainer .submitContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .emailInput {
    width: 20em;
  }
}

/* Tablet (portrait) */
@media (min-width: 768px) {
  .formContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    row-gap: 1em;
    column-gap: 1em;
  }

  .formContainer .labelContainer {
    font-weight: bold;
    margin-top: 0.5em;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .formContainer .inputContainer {
    flex-direction: row;
    align-items: center;
  }

  .formContainer .fullRow {
    width: 100%;
    grid-column-end: span 2;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 992px) {
  .formContainer {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }
}