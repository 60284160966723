/* Phones, Burger Menu */
@media only screen and (min-width: 320px) {
  .outerContainer {
    padding: 0.5em;
    display: flex;
    column-gap: 0.35em;
    align-items: center;
  }

  .outerContainer svg, .outerContainer a {
    display: block;
  }

  .outerContainer a, .outerContainer a:visited, .outerContainer a:hover {
    color: white;
    font-weight: normal;
    text-decoration: none;
  }
}

/* Tablet in Portrait */
@media only screen and (min-width: 768px) {
  .outerContainer.active,.outerContainer.active:hover {
    border-bottom: 3px solid white;
    border-top: 3px solid rgb(174, 0, 0);
  }

  .outerContainer:hover {
    border-bottom: 3px solid rgba(255, 255, 255, 0.7);
    border-top: 3px solid rgb(174, 0, 0);
  }

  .outerContainer:hover {
    cursor: pointer;
  }
}